<template>
  <Card title="菜单管理">
    <div>
      <a-row style="margin-bottom: 2vh">
        <a-col :span="22">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="名称" />
            </a-form-model-item>
            <a-form-model-item>
              <a-radio-group v-model="form.type">
                <a-radio
                  v-for="item in menuTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item>
              <a-radio-group v-model="form.status">
                <a-radio
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item>
              <a-space>
                <a-button type="primary" @click="query">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>

        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button type="primary" @click="$router.push('/setting/menu/add')">
              新建
            </a-button>
          </div>
        </a-col>
      </a-row>
      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        :pagination="false"
        rowKey="id"
      >
        <a-table-column title="名称" data-index="name"></a-table-column>
        <a-table-column title="路径" data-index="path"></a-table-column>
        <a-table-column title="关联权限" data-index="permission">
          <template slot-scope="permission">
            {{ permissionRender(permission) }}
          </template>
        </a-table-column>

        <a-table-column title="类型" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="menuType" :dictValue="text.type" />
          </template>
        </a-table-column>
        <a-table-column title="状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="status"
              :dictValue="String(text.status)"
            />
          </template>
        </a-table-column>
        <a-table-column title="排序" data-index="sort"></a-table-column>
        <a-table-column title="备注" data-index="remarks"></a-table-column>

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a @click.prevent="edit(text)">编辑</a>
              <a class="danger" @click.prevent="deleteText(text)">删除</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </div>
  </Card>
</template>

<script>
import { fetchList as fetchPermissionList } from "@/api/setting/permission";
import { fetchList, remove } from "@/api/setting/menu";
import { mapGetters } from "vuex";
import { buildMenuTree } from "./tools";

export default {
  data() {
    return {
      form: {},
      permissionList: [],

      loading: false,
      list: [],

      visible: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    statusList() {
      return this.findDataDict("status");
    },
    menuTypeList() {
      return this.findDataDict("menuType");
    },
  },

  mounted() {
    fetchPermissionList({
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      if (Array.isArray(res.list)) {
        this.permissionList = Object.freeze(res.list);
      }
    });

    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res)) {
            this.list = Object.freeze(buildMenuTree(res, "0"));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    query() {
      this.getList();
    },
    reset() {
      this.form = {};
      this.getList();
    },

    edit(text) {
      window.localStorage.setItem("menu-detail", JSON.stringify(text));
      this.$router.push("/setting/menu/edit");
    },

    permissionRender(permission) {
      const res = this.permissionList.find((item) => item.code === permission);
      if (res) {
        return res.name;
      } else {
        return "--";
      }
    },
  },
};
</script>


<style lang="less" scoped>
.selector {
  position: fixed;
  top: 11vh;
  left: 17vw;
  right: 1vw;
  z-index: 99;
  background-color: var(--bg-color);

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .query {
    padding: 2vh 1vw 0;
  }
  .table {
    padding: 2vh 1vw;
  }
}
</style>
